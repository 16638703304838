import { createStore } from "vuex";
import { http } from "@/plugins/http";
import { defaultAgendaFormData, getEnv } from "@/helpers";

export const store = createStore({
  state() {
    return {
      httpSignals: {},
      agendaForm: structuredClone(defaultAgendaFormData),
      createdAppointment: null,
      headingText: null,
      headingSubText: null,
      headingImage: null,
      showNavSlider: true,
      timeline: [
        "vehicle",
        "work_type",
        "location",
        "time_slot",
        "confirmation",
      ],
      colorCodes: {
        row: {
          success: "#d1e7dd",
          danger: "#f9d7da",
          warning: "#fff4cd",
          primary: "#cfe2ff",
          secondary: "#e2e3e5",
          info: "cff5fd",
          light: "#f8fafa",
          dark: "#202528",
        },
        progressBar: {
          main: "#1875F7",
          failed: "#874b4b",
        },
        btn: {
          success: "#4caf50",
          warning: "#FB8C00",
          yellow: "#FFFF00",
          danger: "#e47200",
        },
      },
      resourceTypes: [],
      appointments: {},
      appointmentsLoaded: [],
      workPoints: [],
      allotedWorkPoints: [],
      availableWorKPoints: [],
      workTypes: [],
      brands: [],
      application: null,
      workResources: [],
      workResourceVisibility: [],
      appointmentTimetable: [],
      pendingAppointment: null,
      languages: [],
      selectedLanguage: getEnv("VUE_APP_I18N_LOCALE"),
      dateFormat: null,
      dateLocale: null,
      companyProperties: {},
      companyLanguages: [],
    };
  },
  getters: {},
  mutations: {
    SET_AGENDA_FORM_KEY(state, payload) {
      state.agendaForm[payload.key] = payload.value;
    },
    SET_AGENDA_FORM(state, payload) {
      state.agendaForm = payload;
    },
    SET_RESOURCE_TYPES(state, payload) {
      state.resourceTypes = payload;
    },
    SET_APPOINTMENTS(state, payload) {
      state.appointments[payload.key] = payload.value;
    },
    SET_WORK_POINTS(state, payload) {
      state.workPoints = payload;
    },
    SET_WORK_TYPES(state, payload) {
      state.workTypes = payload;
    },
    SET_BRANDS(state, payload) {
      state.brands = payload;
    },
    SET_ALLOTED_WORK_POINTS(state, payload) {
      state.allotedWorkPoints = payload;
    },
    SET_APPLICATION(state, payload) {
      state.application = payload;
    },
    SET_WORK_RESOURCES(state, payload) {
      state.workResources = payload;
    },
    SET_WORK_RESOURCES_VISIBILITY(state, payload) {
      state.workResourceVisibility = payload;
    },
    SET_APPOINTMENT_TIMETABLE(state, payload) {
      state.appointmentTimetable = payload;
    },
    SET_PENDING_APPOINTMENT(state, payload) {
      state.pendingAppointment = payload;
    },
    SET_LANGUAGES(state, payload) {
      state.languages = payload;
    },
    SET_SELECTED_LANGUAGE(state, payload) {
      state.selectedLanguage = payload;
    },
    SET_DATE_LOCALE(state, payload) {
      state.dateLocale = payload;
    },
    SET_DATE_FORMAT(state, payload) {
      state.dateFormat = payload;
    },
    SET_COMPANY_PROPERTIES(state, payload) {
      state.companyProperties[payload.key] = payload.value;
    },
    SET_COMPANY_LANGUAGES(state, payload) {
      state.companyLanguages = payload;
    },
  },
  actions: {
    async getResourceTypes(context) {
      if (context.state.resourceTypes.length > 0) {
        return;
      }
      await http
        .get("codes?class_key=resource_type")
        .then(({ data }) => context.commit("SET_RESOURCE_TYPES", data));
    },
    async getAppointments(context, payload) {
      await http
        .get(
          `appointments?start_date=${payload["start_date"]}&end_date=${payload["end_date"]}`
        )
        .then(({ data }) => {
          let output = {};

          payload.allDate.forEach((date) => {
            output[date] = data[date] || [];
          });

          context.state.appointments = output;
        });
    },
    async getWorkPoints(context) {
      if (context.state.workPoints.length > 0) {
        return;
      }
      await http
        .get("work_points")
        .then(({ data }) => context.commit("SET_WORK_POINTS", data));
    },
    async getWorkTypes(context) {
      if (context.state.workTypes.length > 0) {
        return;
      }
      await http
        .get("work_types")
        .then(({ data }) => context.commit("SET_WORK_TYPES", data));
    },
    async getBrands(context) {
      if (context.state.brands.length > 0) {
        return;
      }
      await http
        .get("brands")
        .then(({ data }) => context.commit("SET_BRANDS", data));
    },
    getAllotedWorkPoints({ commit }, workType) {
      let output = [];
      workType.resource_work_types.forEach((resourceWorkType) => {
        let workPointId = resourceWorkType.work_resource.work_point_id;
        if (!output.includes(workPointId)) {
          output.push(workPointId);
        }
      });
      commit("SET_ALLOTED_WORK_POINTS", output);

      return output;
    },
    async getApplication(context) {
      await http
        .get("application/" + getEnv("VUE_APP_APPLICATION_NAME"))
        .then(({ data }) => context.commit("SET_APPLICATION", data));
    },
    async getWorkResources(context) {
      if (context.state.workResources.length > 0) {
        return;
      }

      await http
        .get("work_resources")
        .then(({ data }) => context.commit("SET_WORK_RESOURCES", data));
    },
    async getWorkResourceVisibility(context) {
      if (context.state.workResourceVisibility.length > 0) {
        return;
      }

      await http
        .get("work_resources_visibility")
        .then(({ data }) =>
          context.commit("SET_WORK_RESOURCES_VISIBILITY", data)
        );
    },
    async getAppointmentTimetable(context) {
      // if (context.state.appointmentTimetable.length > 0) {
      //   return;
      // }
      await http
        .get(`appointment_timetable`)
        .then(({ data }) => context.commit("SET_APPOINTMENT_TIMETABLE", data));
    },
    async getLanguages(context) {
      await http
        .get("languages")
        .then(({ data }) => context.commit("SET_LANGUAGES", data));
    },
    async getCompanyProperty(context, payload) {
      await http.get(`company_properties/${payload}`).then((data) => {
        context.commit("SET_COMPANY_PROPERTIES", {
          key: payload,
          value: data,
        });
      });
    },
    async getCompanyLanguages({ commit }) {
      await http.get("company_languages").then((response) => {
        commit(
          "SET_LANGUAGES",
          response.data.map((language) => language.language)
        );
        commit("SET_COMPANY_LANGUAGES", response.data);
      });
    },
  },

  modules: {},
});
