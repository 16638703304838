<template>
  <div>
    <a-config-provider :locale="dateLocale">
      <vue-progress-bar></vue-progress-bar>
      <div><NavBar /></div>
      <router-view></router-view>
      <notifications position="bottom left" />
    </a-config-provider>
    <FooterNav />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterNav from "./components/FooterNav.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    NavBar,
    FooterNav,
  },
  computed: {
    ...mapState([
      "headingText",
      "headingSubText",
      "showNavSlider",
      "selectedLanguage",
      "dateLocale",
      "companyProperties",
    ]),
  },

  async created() {
    // document.querySelector("body").style.background =
    //   "url('/img/bg1.jpeg') 0% 0% / cover no-repeat";

    document.querySelector("body").style.background = "url('/img/bg2.jpg') 0% 0% / cover no-repeat";

    // Add Cookie Consent
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#000",
        },
        button: {
          background: "#f1d600",
        },
      },
      position: "bottom",
    });

    await this.$store.dispatch("getApplication");

    // Get Work Points
    await this.$store.dispatch("getWorkPoints");

    // Get Company Logo
    await this.$store.dispatch("getCompanyProperty", "LOGO");
  },
  beforeMount() {
    let savedForm = window.localStorage.getItem("agendaForm");
    if (savedForm) {
      this.$store.state.agendaForm = JSON.parse(savedForm);
    }
  },
};
</script>
